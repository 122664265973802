import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';

import axios from "../../axios";
import ChatInput from "./helper_section/ChatInput";
import ChatInput2 from "./helper_section/ChatInput2";
import ChatInput3 from "./helper_section/ChatInput3";
import ChatInput4 from "./helper_section/ChatInput4";
import MultiSelect from "./helper_section/MultiSelect";
import MultiSelectDnD from "./helper_section/multiSelectDnd/MultiSelectDnD";
import Dnd from "./helper_section/Dnd";
import ChatVoice from "./helper_section/ChatVoice";
import Feedback from "./Feedback";
import EsyncSpreadSheet from "./helper_section/spreadsheet/EsyncSpreadSheet";

import "../../styles/btn.css";
import '../../styles/modal.css';
import '../../styles/helperSection.scss';
import styles from '../../styles/MultiSelect.module.css';

const HelperSection = (props) => {
	const { messages, addToMessageBox, setNextQues, nextQues, setSeeQues, setCaseState, setProgress, setStopChat, addToExhibitObjs, startTime, startT, totalChatTime } = props;

	const url = '/userconvo';

	let location = useLocation();

	const [showFeedback, setShowFeedBack] = useState(false);
	const [isExcel, setIsExcel] = useState(false);

	useEffect(() => {
		let flag = false;
		messages.messages.forEach((item) => {
			if(item.q.Workbook) {
				flag = true;
				return;
			}
		});

		setIsExcel(flag);
	}, [messages]);

	const onOpenModal = () => {
		setStopChat();

		const postData = {
			user_id: location.state.code,
			comp: location.state.comp,
			mode: 3,
			message1: '',
			message2: ''
		};

		axios
			.post(url, postData)
			.then((res) => {
				setCaseState(false);
				setShowFeedBack(true);
			})
			.catch((err) => {
				console.log(err);
			})
	};

	const ele = () => {
		if (messages?.messages[0]?.qtype === "tac") {
			return null
		}

		if (messages.messages[0].qid === "-1")
			return <div id="chat-form" style={{ height: "8vh", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: "flex", alignItems: "center", justifyItems: "center" }}>
				<Button className={styles.SubmitButton} onClick={onOpenModal}>
					<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Submit</span>
				</Button>
			</div>

		if (nextQues.length == 0 && !messages.messages[0].qtype)
			return null;

		if (!messages.messages[0].qtype && !messages.messages[0].isMyMessage)
			return <ChatInput3 addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} setSeeQues={setSeeQues} />

		if (messages.messages[0].qtype[0] === "v") {
			return null;
		}

		if (messages.messages[0].qtype === "txt0") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}

		if (messages.messages[0].qtype === "txt1") {
			return <ChatInput4 addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} addToExhibitObjs={addToExhibitObjs} />
		}

		if (messages.messages[0].qtype === "txt2") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}

		if (messages.messages[0].qtype === "txt3") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}

		if (messages.messages[0].qtype === "txt5") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}

		if (messages.messages[0].qtype === "xls0" || messages.messages[0].qtype === "xls1") {
			return <EsyncSpreadSheet addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} addToExhibitObjs={addToExhibitObjs} isWorkableExcel={isExcel} startTime={startTime} startT={startT} totalChatTime={totalChatTime} />
		}

		if (
			messages.messages.length === 0 ||
			!("qtype" in messages.messages[0]) ||
			messages.messages[0].qtype[0] === "n"
		) {
			if (messages.messages[0].qtype[3] === "0")
				return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
			else {
				let noOfInputs = parseInt(messages.messages[0].qtype[3]);

				return <ChatInput2 addToMessageBox={addToMessageBox} noOfInputs={noOfInputs} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
			}
		} else if (messages.messages[0].qtype[0] === "v") {
			return <ChatVoice addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		} else if (messages.messages[0].qtype[0] === "m") {
			let instruction;

			if (messages.messages[0].qtype[3] === "0")
				instruction = "Choose any number of options from above"
			else if (messages.messages[0].qtype.includes('max'))
				instruction = `Choose up to ${messages.messages[0].qtype[3]} options from the list above `
			else if (messages.messages[0].qtype[3] === "1")
				instruction = "Choose one option from the list above"
			else
				instruction = `Choose ${messages.messages[0].qtype[3]} options from the list above `

			return (
				<>
					<MultiSelect
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
						addToExhibitObjs={addToExhibitObjs}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={instruction} />
				</>
			);
		} else if (messages.messages[0].qtype[0] === "s") {
			return (
				<>
					<Dnd
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
						addToExhibitObjs={addToExhibitObjs}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={"Drag to Rearrange"} />
				</>
			);
		} else if (messages.messages[0].qtype[0] === "r") {
			let instruction;

			if (messages.messages[0].qtype[3] === "0")
				instruction = "Choose any number of options from above"
			else if (messages.messages[0].qtype.includes('max'))
				instruction = `Select up to ${messages.messages[0].qtype[3]} options and order them logically`
			else if (messages.messages[0].qtype[3] === "1")
				instruction = "Choose one option from the list above"
			else
				instruction = `Select up to ${messages.messages[0].qtype[3]} options and order them logically`

			return (
				<>
					<MultiSelectDnD
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
						addToExhibitObjs={addToExhibitObjs}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={instruction} />
				</>
			);
		}
	};

	let renderElement = ele();

	return (
		<div id="helperSection" >
			<Feedback showFeedback={showFeedback} setShowFeedBack={setShowFeedBack} />
			{renderElement}
		</div>
	);
};

HelperSection.propTypes = {
	messages: PropTypes.object,
	addToMessageBox: PropTypes.func,
	setNextQues: PropTypes.func,
	nextQues: PropTypes.array,
	setSeeQues: PropTypes.func,
	setCaseState: PropTypes.func,
	setProgress: PropTypes.func,
	setStopChat: PropTypes.func
}

export default HelperSection;
