import React, { useEffect, useState, useRef } from "react";
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import D3Funnel from 'd3-funnel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from "@mui/x-data-grid";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import { Select } from "@mui/material";
import { useTour } from '@reactour/tour';

import AccountMenu from "../accountMenu/AccountMenu";
import AddNewDrive from "../../components/workflows/AddNewDrive";
import axios from "../../axios";
import NotificationBell from "../notificationBell/notificationBell";
import {
	fetchCompanyBots as fetchCompanyBotsRequest,
	editDriveName as editDriveNameRequest,
	resetRerender as resetRerenderRequest,
	setWorkFlowFunnelData as setWorkFlowFunnelDataRequest, setReportModel as setReportModelRequest,
	setDashboardLogo as setDashboardLogoRequest,
	fetchMyDetails as fetchMyDetailsRequest,
	updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
	updateTrackerInfo as updateTrackerInfoRequest
} from '../../actions/app';
import { getCompanyBots, getCompanyFeatures, getCurrentWalkthroughStep, getRerender, getSaasPlanDetails, getUserName, getUserPlan, getUserRole } from '../../selectors/app';

import Edit from '../../assets/Icons/Edit.png';
import emptyRowImg from '../../assets/emptyrow1.png';
import tourendimg from '../../assets/Done-rafiki.gif';
import tourstartimg from '../../assets/tourstartimg.gif';
import Trash from '../../assets/Icons/Trash.png';

import "./Workflow.css";
import "../../styles/table.css";
import 'react-responsive-modal/styles.css';
import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../../utils/featureAccess";
import { companyCheck, redirectUnauthorisedUser } from "../../helper/helperFunction";
import WalkthroughOverlay from "../../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../../components/common/WalkthroughWelcome";

const btnStyle = {
	background: '#fff',
	fontFamily: "Poppins",
	letterSpacing: "0.01em",
	borderRadius: '25px',
	padding: "2px 25px"
}

const Workflow = (props) => {
	const {
		userName,
		setShouldShowTour,
		shouldShowTour,
		setWorkflowFunnelData,
		editDriveName,
		rerender,
		resetRerender,
		setReportModel,
		notification,
		refObj,
		userPlan,
		userRole,
		saasPlanDetails,
		setDashboardLogo,
		fetchMyDetails,
		fetchCompanyBots,
		companyBots,
		companyFeatures,
		startTutorial,
		currentWalkthroughStep,
		updateTrackerInfo,
		updateCurrentWalkthroughStep
	} = props;

	const location = useLocation();
	let history = useHistory();
	const { setIsOpen, setSteps, isOpen } = useTour();

	const [allWorkflows, setAllWorkflows] = useState([]);
	const [selectionModel, setSelectionModel] = useState([]);
	const [workflows, setWorkflows] = useState([]);
	const [companyName, setCompanyName] = useState();
	const [createRecruitmentErrors, setCreateRecruitmentErrors] = useState({
		name: false,
		bot: false
	});
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [change, setChange] = useState(0);
	const [loading, setLoading] = useState(true);
	const [selectBotMenu, setSelectBotMenu] = useState(null);
	const [convo2, setConvo2] = useState([]);
	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');
	const [bot, setBot] = useState();
	const [open, setOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [pageSize, setPageSize] = useState(15);
	const [tourModel, setTourModel] = useState(false);
	const [tourEndModel, setTourEndModel] = useState(false);
	const [tourDrive, setTourDrive] = useState();
	const [addNewDrive, setAddNewDrive] = useState(false);
	const [walkthroughWelcomeModal, setWalkthroughWelcomeModal] = useState(localStorage.getItem('walkthrough') ? (localStorage.getItem('walkthrough') === 'true') : true);

	const ref = useRef(null);
	const editNameRef = useRef(null);

	const onOpenDeleteModal = () => {
		if (selectionModel.length > 0) setDeleteModal(true);
		else {
			setSnackErr('Please select atleast one recruitment drive')
			handleSnackClick();
		}
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);
		if (selectionModel.length == 1) setSelectedRow(workflows.filter((row) => selectionModel[0] == row.id));
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setSnackErr('Please select atleast one Recruitment Drive')
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const workflowEdit = function (row) {
		editDriveName({row, newName: editNameRef.current.value, history});
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	useEffect(() => {
		if (companyBots.length == 0) return;

		companyCheck(companyBots, setConvo2);
	}, [companyBots]);

	useEffect(() => {
		if (convo2.length == 0) return;

		let botMenu = convo2.map((res, i) => <MenuItem key={i} value={res}>{res.name}</MenuItem>);

		setSelectBotMenu(botMenu);
	}, [convo2]);

	useEffect(() => {
		fetchCompanyBots({ history });
	}, [change]);

	const onOpenAddNewDrive = () => {
		updateTrackerInfo({
						heading: "CLICK_ADD_DRIVE",
						rawRequest : JSON.stringify({}),
						detail : currentWalkthroughStep > 0?"Walkthrough" : "Normal"
					})
		if (userRole === 'Member') {
			setSnackErr('You are not authorised to do this action')
			handleSnackClick();
		} else {
			if(checkCompanyFeatureAccess(companyFeatures, 'driveCount', workflows.length) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'driveCount', workflows.length)) {
				fetchMyDetails({ history });
				setAddNewDrive(true);
				if(currentWalkthroughStep > 0) {
					updateCurrentWalkthroughStep(3);
				}
			} else {
				setSnackErr('Your quota is full')
				handleSnackClick();
			}
		}
	}

	const onCloseAddNewDrive = () => {
		setAddNewDrive(false);
	}


	const filterResponse = (response) => {
		let funnelScores = { total: 0, submitted: 0, hired: 0, shortlisted: 0 };
		let tempWorkflows = [];

		setCompanyName(response.data.name);

		if(response.data.logo) {
			localStorage.setItem('dashboardLogo', 'https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/' + response.data.logo);
			setDashboardLogo('https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/' + response.data.logo);
		}

		if (process.env.REACT_APP_BASE_URL.includes('test.hirequotient.org')) {
			tempWorkflows = [
				{
					id: 0,
					caseName: "Finance Associate",
					workflowId: "1",
					invitedCount: 500,
					totalCount: 3000,
					startedCount: 2595,
					awaitApprovalCount: 0,
					underReviewCount: 2500,
					reviewedCount: 0
				},
				{
					id: 1,
					caseName: "Investment Banker",
					workflowId: "2",
					invitedCount: 25,
					totalCount: 590,
					startedCount: 565,
					awaitApprovalCount: 0,
					underReviewCount: 550,
					reviewedCount: 150
				},
				{
					id: 2,
					caseName: "Consultant",
					workflowId: "3",
					invitedCount: 78,
					totalCount: 1198,
					startedCount: 1120,
					awaitApprovalCount: 0,
					underReviewCount: 1089,
					reviewedCount: 100
				}]
			funnelScores = { total: 4788, submitted: 4139, hired: 20, shortlisted: 250 }
		}

		let idxxx = tempWorkflows.length;

		response.data.workflows.reverse().forEach((wf, i) => {
			const workflow = {
				id: i + idxxx,
				caseName: wf.name,
				workflowId: wf._id,
				invitedCount: wf.invite.length,
				totalCount: wf.candidates.length,
				startedCount: wf.progress.length + wf.review.length + (wf.shortlisted.length + wf.hired.length + wf.rejected.length + wf.kiv.length),
				awaitApprovalCount: 0,
				underReviewCount: (wf.review.length + wf.shortlisted.length + wf.hired.length + wf.rejected.length + wf.kiv.length),
				reviewedCount: (wf.shortlisted.length + wf.hired.length + wf.rejected.length + wf.kiv.length)
			}
			funnelScores.total = funnelScores.total + wf.candidates.length
			funnelScores.hired = funnelScores.hired + wf.hired.length
			funnelScores.shortlisted = funnelScores.shortlisted + wf.shortlisted.length
			funnelScores.submitted = funnelScores.submitted + workflow.underReviewCount;
			tempWorkflows.push(workflow);
		});

		const optionf = {
			block: {
				dynamicHeight: true,
				dynamicSlope: false,
				minHeight: 10,
				fill: {
					type: 'gradient',
					scale: ['#4FCA64', '#54C5EB', '#FFB74A', '#22242C']
				}
			},
			tooltip: {
				enabled: true,
				format: '{l} : {f}'
			},
			label: { enabled: false },
			chart: {
				curve: {
					height: 15,
					enabled: true
				},
				totalCount: funnelScores.total * 2,
				inverted: false,
				height: 175
			}
		};
		const dataf = [
			{ label: 'Invited', value: funnelScores.total },
			{ label: 'Submitted', value: funnelScores.submitted },
			{ label: 'Shortlisted', value: funnelScores.shortlisted },
			{ label: 'Hired', value: funnelScores.hired },
		];

		if (refObj.current) {
			const chart = new D3Funnel(refObj.current);
			chart.draw(dataf, optionf);
			refObj.current.style.width = 'inherit';
			refObj.current.style.height = '200px';
		}

		setWorkflowFunnelData(funnelScores);
		setWorkflows(tempWorkflows);
		setAllWorkflows(tempWorkflows);
		setLoading(false)
	}

	useEffect(() => {
		console.log(location.state)
		if (location.state?.company) { //undefined
			axios.get('/admin/getCompany/' + location.state.company._id)
				.then((response) => {
					filterResponse(response);
				}).catch((err) => {
					console.log(err)
					setLoading(false);

					redirectUnauthorisedUser(err, history);
				});
		} else {
			axios.get('/company').then((response) => {
				filterResponse(response)
			}).catch((err) => {
				console.log(err)
				setLoading(false);

				redirectUnauthorisedUser(err, history);
			});
		}
	}, [change]);

	const onOpenModal = () => setOpen(true);

	const onCloseModal = () => setOpen(false);

	const getInvitedCount = (params) => {
		return params.row.totalCount;
	}

	const getDriveName = (params) => {
		return params.row.caseName;
	}

	const getStartedCount = (params) => {
		return params.row.startedCount;
	}

	const getSubmittedCount = (params) => {
		return params.row.underReviewCount;
	}

	const getShortlistedCount = (params) => {
		return params.row.reviewedCount;
	}

	const validateCreateRecruitmentForm = () => {
		let validation = true;
		const newCreateRecruitmentErrors = { ...createRecruitmentErrors };

		Object.keys(createRecruitmentErrors).forEach((val) => {
			switch (val) {
				case 'name': if (name === '') {
					newCreateRecruitmentErrors.name = true;
					validation = false;
				}
					break;
				case 'bot': if (!bot) {
					newCreateRecruitmentErrors.bot = true;
					validation = false;
				}
					break;
				default: newCreateRecruitmentErrors[val] = false;
			}
		});

		setCreateRecruitmentErrors(newCreateRecruitmentErrors);
		return validation;
	}

	const onChangeName = (e) => {
		const newCreateRecruitmentErrors = { ...createRecruitmentErrors };
		newCreateRecruitmentErrors.name = false;

		setName(e.target.value);
		setCreateRecruitmentErrors(newCreateRecruitmentErrors);
	}

	const onChangeDesc = (e) => {
		setDesc(e.target.value);
	}

	const onChangeBot = (e) => {
		const newCreateRecruitmentErrors = { ...createRecruitmentErrors };
		newCreateRecruitmentErrors.bot = false;

		setBot(e.target.value);
		setCreateRecruitmentErrors(newCreateRecruitmentErrors);
	}

	const sendWorkflow = () => {
		if (validateCreateRecruitmentForm()) {
			const uri = "/company/workflow/";
			const getData = {
				workflowName: name,
				workflowDescription: desc,
				productId: bot.productId,
				problem: bot.description,
				title: bot.name,
				companyName: companyName,
			}

			axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then(() => {
					setChange(change + 1);
				})
				.catch((err) => {
					console.log(err);
				});

			onCloseModal();
		}
	}

	const columns = [
		{
			field: "driveName",
			headerName: "Drive Name",
			width: 200,
			valueGetter: getDriveName,
			headerAlign: "left",
			renderCell: (params) => {
				return (
					<>
						<Link style={{ marginLeft: "5px", textAlign: "left", textDecoration: "none", width: "100%", color: "black", fontSize: "15px", fontWeight: "400" }} to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "candidatesInvited",
			headerAlign: "center",
			headerName: "Invited",
			align: 'center',
			minWidth: 140,
			flex: 0.5,
			type: 'number',
			valueGetter: getInvitedCount,
			renderCell: (params) => {
				return (
					<>
						<Link style={{ marginRight: "4ch", textDecoration: "none", width: "100%", color: "black" }} to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "started",
			headerName: "Started",
			headerAlign: "center",
			align: 'center',
			type: 'number',
			minWidth: 140,
			flex: 0.4,
			valueGetter: getStartedCount,
			renderCell: (params) => {
				return (
					<>
						<Link style={{ marginRight: "4ch", textDecoration: "none", width: "100%", color: "black" }} to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "submitted",
			headerName: "Submitted",
			minWidth: 140,
			flex: 0.5,
			valueGetter: getSubmittedCount,
			align: 'center',
			type: 'number',
			headerAlign: "center",
			renderCell: (params) => {
				return (
					<>
						<Link style={{ marginRight: "4ch", textDecoration: "none", width: "100%", color: "black" }} to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "shortlisted",
			headerName: "Shortlisted",
			minWidth: 140,
			headerAlign: "center",
			flex: 0.5,
			valueGetter: getShortlistedCount,
			align: 'center',
			type: 'number',
			renderCell: (params) => {
				return (
					<>
						<Link style={{ marginRight: "4ch", textDecoration: "none", width: "100%", color: "black" }} to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Analysis",
			headerAlign: "center",
			align: 'center',
			width: 100,
			flex: 0.5,
			sortable: false,
			filterable: false,
			renderCell: (params) => {
				return (
					<div style={{ padding: "5px 20px", width: "100%" }}>
						<Link to={{ pathname: "/workflows/" + params.row.caseName, state: { workflowId: params.row.workflowId, workflowName: params.row.caseName, sidebar: "quickLook" } }} style={{ textDecoration: 'none' }}>
							<Button
								className="inner-div-btn"
								id="quickViewFlow-selector-1"
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 10px',
									fontSize: '14px'
								}}
								size="small"
								variant="contained"
							>
								<span>View</span>
							</Button>
						</Link>
					</div>
				);
			},
		},
	];

	const workflowDelete = async () => {
		selectionModel.map((cand) => {
			console.log(cand)
			console.log(workflows)
			console.log(allWorkflows[cand])
			let id = allWorkflows[cand].workflowId;
			axios.delete(("/workflow/" + id))
				.then(() => {
					setWorkflows(workflows.filter((item) => item.workflowId !== id));
					setChange(change + 1);
				})
				.catch(err => {
					console.log(err);
					redirectUnauthorisedUser(err, history);
				})
		});

		setSelectionModel([]);
	}

	const escapeRegExp = (value) => {
		return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	}

	const QuickSearchToolbar = (props) => {
		const { value, onChange, clearSearch } = props;

		return (
			<Box style={{ border: "none" }}>
				<TextField
					ref={ref}
					value={value}
					onChange={onChange}
					placeholder="Search…"
					InputProps={{
						startAdornment: <SearchIcon fontSize="small" />,
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: value ? 'visible' : 'hidden' }}
								onClick={clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						),
					}}
					sx={{
						width: {
							xs: 1,
							sm: 'auto',
						},
						m: (theme) => theme.spacing(1, 0.5, 1.5),
						'& .MuiSvgIcon-root': {
							mr: 0.5,
						},
						'& .MuiInput-underline:before': {
							borderBottom: 1,
							borderColor: 'divider',
						},
					}}
				/>
			</Box>
		)
	}

	QuickSearchToolbar.propTypes = {
		clearSearch: PropTypes.func.isRequired,
		onChange: PropTypes.func.isRequired,
		value: PropTypes.string.isRequired,
	};

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue == '') {
			setWorkflows(allWorkflows);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = allWorkflows.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setWorkflows(filteredRows);
		}
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No drives to show</p>
			</div>
		)
	}

	const onOpenTourModel = () => setTourModel(true);

	const onCloseTourModel = () => setTourModel(false);

	const onOpenTourEndModel = () => setTourEndModel(true);

	const onCloseTourEndModel = () => setTourEndModel(false);

	useEffect(() => {
		if (shouldShowTour) {
			onOpenTourModel();
			setShouldShowTour(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (workflows.length === 0) return;

		setTourDrive({
			id: 0,
			caseName: "Finance Associate",
			workflowId: "1",
			invitedCount: 500,
			totalCount: 3000,
			startedCount: 2595,
			awaitApprovalCount: 0,
			underReviewCount: 2500,
			reviewedCount: 0
		});
	}, [workflows]);

	useEffect(() => {
		if (tourDrive) {
			setSteps(steps);
		}
	}, [tourDrive]);

	const steps = !tourDrive ? [] : [
		{
			selector: '.dashboard-first-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Create new drives</h4>
							<p>Step 1 of 9</p>
						</div>
						<p>Start by creating a new recruitment drive for your new hiring campaign. Eg: Associate hiring (Spring’22)</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								id="testDashboardTourFlow-selector-15"
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => {
									setIsOpen(false);
								}}
							>
								Skip tour
							</a>
							<div>
								<Button
									id="testDashboardTourFlow-selector-2"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/new-applicants",
											state: {
												sidebar: "invitations",
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
											}
										});
										setCurrentStep(1);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-second-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Invite candidates to attempt the assessment</h4>
							<p>Step 2 of 9</p>
						</div>
						<p>It’s an easy 3 step process:</p>
						<ul style={{ margin: "10px 0" }}>
							<li style={{ margin: "5px 0" }}>Customise mails, add your brand identity and set your own templates</li>
							<li style={{ margin: "5px 0" }}>Add applicants (individually or by uploading a csv)</li>
							<li style={{ margin: "5px 0" }}>Send out invitation mails and schedule automatic reminders</li>
						</ul>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => {
									setIsOpen(false);
								}}
							>
								Skip tour
							</a>
							<div>
								<a
									id="testDashboardTourFlow-selector-14"
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push('/workflows')
										setCurrentStep(0);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-3"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/review-applicants",
											state: {
												sidebar: "reviewApplicants",
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
											}
										})
										setCurrentStep(2)
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-third-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Review Applicants</h4>
							<p>Step 3 of 9</p>
						</div>
						<p>Evaluate candidates' performance and decide whether to Shortlist, Reject or Keep in View (allows you to hold that application for evaluation later)</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => {
									setIsOpen(false);
								}}
							>
								Skip tour
							</a>
							<div>
								<a
									id="testDashboardTourFlow-selector-13"
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push({
											pathname: "/new-applicants",
											state: {
												sidebar: "invitations",
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
											}
										});
										setCurrentStep(1);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-4"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										setReportModel(true);
										setTimeout(() => setCurrentStep(3), 200);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-forth-step',
			position: 'top',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Performance reports</h4>
							<p>Step 4 of 9</p>
						</div>
						<p>View or download candidate’s report, video answers and chat transcripts to help you make your decision. You can also access the Interviewer’s Guide to understand the role-specific skills and key tasks judged in the assessment, and to help your interviewers focus on the key issues.</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => setIsOpen(false)}
							>
								Skip tour
							</a>
							<div>
								<a
									id="testDashboardTourFlow-selector-12"
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										setReportModel(false);
										setCurrentStep(2);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-5"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/workflows/" + tourDrive.caseName,
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "quickLook"
											}
										});
										setReportModel(false);
										setCurrentStep(4);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-fifth-step',
			highlightedSelectors: ['.dashboard-fifth-step', '.dashboard-fifth-step-2'],
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Quick Overview</h4>
							<p>Step 5 of 9</p>
						</div>
						<p>Check out the performance of your recruitment drive with our quick overview. You can also copy the assessment link from the Chatbot Link button to use it for internal team testing.</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => setIsOpen(false)}
							>
								Skip tour
							</a>
							<div>
								<a
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push({
											pathname: "/review-applicants",
											state: {
												sidebar: "reviewApplicants",
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
											}
										});
										setReportModel(true);
										setTimeout(() => setCurrentStep(3), 200);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-6"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/finalized",
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "finalised"
											}
										});
										setCurrentStep(5);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-sixth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Reviewed Candidates</h4>
							<p>Step 6 of 9</p>
						</div>
						<p>See a list of shortlisted and rejected candidates, and send out customised mails to inform them of the outcome.</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => setIsOpen(false)}
							>
								Skip tour
							</a>
							<div>
								<a
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push({
											pathname: "/workflows/" + tourDrive.caseName,
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "quickLook"
											}
										});
										setCurrentStep(4);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-7"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/analytics",
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "analytics"
											}
										});
										setCurrentStep(6);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-seventh-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Analytics</h4>
							<p>Step 7 of 9</p>
						</div>
						<p>Make data-driven decisions by analysing the performance of your recruitment drive.</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => setIsOpen(false)}
							>
								Skip tour
							</a>
							<div>
								<a
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push({
											pathname: "/finalized",
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "finalized"
											}
										});
										setCurrentStep(5);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-8"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/settings",
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "settings"
											}
										});
										setCurrentStep(7);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-eighth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Settings</h4>
							<p>Step 8 of 9</p>
						</div>
						<p>Choose whether you want to enable/ disable viewing reports, chat transcripts and video submissions in your dashboard screen. You can also decide if you want to send customised post-assessment mails to candidates to confirm their submissions were successful.</p>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee" }}
								onClick={() => setIsOpen(false)}
							>
								Skip tour
							</a>
							<div>
								<a
									href="javascript:void(0)"
									style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
									onClick={() => {
										history.push({
											pathname: "/analytics",
											state: {
												workflowId: tourDrive.workflowId,
												workflowName: tourDrive.caseName,
												sidebar: "analytics"
											}
										});
										setCurrentStep(6);
									}}
								>
									Back
								</a>
								<Button
									id="testDashboardTourFlow-selector-9"
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										history.push({
											pathname: "/team",
											state: {
												sidebar: "team"
											}
										});
										setCurrentStep(8);
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			selector: '.dashboard-ninth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return (
					<div style={{ color: "#fff" }}>
						<div
							style={{
								color: "#eee",
								fontSize: "12px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "10px"
							}}>
							<h4 style={{ fontSize: '20px' }}>Manage team access</h4>
							<p>Step 9 of 9</p>
						</div>
						<p>Invite your coworkers and set their access levels</p>
						<ul style={{ margin: "10px 0" }}>
							<li style={{ margin: "5px 0" }}>Admin: Has authority to perform actions like inviting, shortlisting etc</li>
							<li style={{ margin: "5px 0" }}>Member: Only has authority to view lists, but not perform any function</li>
						</ul>
						<br />
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<a
								href="javascript:void(0)"
								style={{ color: "#eee", marginRight: "10px", textDecoration: "none", fontWeight: "600" }}
								onClick={() => {
									history.push({
										pathname: "/settings",
										state: {
											workflowId: tourDrive.workflowId,
											workflowName: tourDrive.caseName,
											sidebar: "settings"
										}
									});
									setCurrentStep(7);
								}}
							>
								Back
							</a>
							<Button
								id="testDashboardTourFlow-selector-10"
								size="small"
								variant="contained"
								style={btnStyle}
								onClick={() => {
									setIsOpen(false);
									history.push("/workflows");
									onOpenTourEndModel();
								}}
							>
								<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Finish</span>
							</Button>
						</div>
					</div>
				)
			},
		},
	]

	return (
		<div className="productList">
			<Modal open={tourModel} onClose={onCloseTourModel} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>
				<div style={{ textAlign: "center" }}>
					<div style={{ color: '#3c3c3c' }}>
						<h1 style={{ color: "inherit", fontWeight: "500" }}>Hello {userName},</h1>
						<h1 style={{ color: "inherit", fontWeight: "500" }}>Welcome to HQ dashboard</h1>
					</div>
					<div style={{ margin: '10px 0 0 0' }}>
						<img
							style={{
								width: '300px'
							}}
							src={tourstartimg}
							alt="user-logo"
						/>
					</div>
					<h4 style={{ color: "inherit", margin: "10px 0", fontWeight: "500" }}>Want to view our quick user guide ?</h4>
					<div style={{ margin: "10px 0" }}>
						<Button
							size="small"
							variant="contained"
							className="cancel-modal-button"
							style={{
								backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '2px 30px',
							}}
							onClick={() => {
								onCloseTourModel();
								setIsOpen(true);
							}}
							id="testDashboardTourFlow-selector-1"
						>
							<span style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'none' }}>Take tour</span>
						</Button>
					</div>
					<div>
						<a
							href="javascript:void(0)"
							className="cancel-modal-button"
							style={{
								background: 'transparent',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
							}}
							onClick={() => {
								onCloseTourModel();
							}}
							id="testLogoutFlow-selector-1"
						>
							<span style={{ color: '#3c3c3c', fontSize: '16px', textDecoration: 'underline' }}>No thanks, I'll take it from here</span>
						</a>
					</div>
				</div>
			</Modal>
			<Modal open={tourEndModel} onClose={onCloseTourEndModel} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>
				<div style={{ textAlign: "center" }}>
					<div style={{ margin: '10px 0 0 0' }}>
						<img
							style={{
								width: '300px'
							}}
							src={tourendimg}
							alt="user-logo"
						/>
					</div>
					<h1 style={{ margin: "10px 0", fontWeight: "600", color: "#686970" }}>That concludes the tour</h1>
					<p style={{ color: "#686970", marginBottom: "20px", fontWeight: "600" }}>
						If you have any problems or issues, we are here to help. Feel free to browse our Help Center or open a
						ticket with our support team if you'd like to learn more.
					</p>
					<div style={{ margin: "10px 0" }}>
						<Button
							size="small"
							variant="contained"
							className="cancel-modal-button"
							style={{
								backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '2px 30px',
							}}
							onClick={() => {
								onCloseTourEndModel();
							}}
						>
							<span style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'none' }}>Finish</span>
						</Button>
					</div>
				</div>
			</Modal>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete this recruitment drive?
				</p>
				<div className="button-div">
					<Button
						id="testDeleteDriveFlow-selector-2"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
							workflowDelete()
						}}>
						Yes
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit drive name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="testEditDriveNameFlow-selector-2"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].caseName}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="testEditDriveNameFlow-selector-3"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							workflowEdit(selectedRow[0])
						}}>
						Ok
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<div className="productTitleContainer">
				<Modal open={open} onClose={onCloseModal} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
					<div className="modal-heading-workflow" style={{ fontFamily: "Mulish", "fontWeight": "600", "color": "#000000" }}>
						Create new recruitment drive
					</div>
					<div className="input-div-workflow">
						<input type="text" placeholder="Name" onChange={onChangeName} style={{ "background": "#F2F2F2", "borderRadius": "30px" }} required /><br />
						{
							createRecruitmentErrors.name && <div style={{ color: 'red', fontSize: 14, marginLeft: 24 }}>Please Enter name</div>
						}
						<textarea type="text" placeholder="Description" onChange={onChangeDesc} style={{ "background": "#F2F2F2", "borderRadius": "30px" }} required /><br />
						<Select
							className="remove-bg"
							onChange={onChangeBot}
							style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
							displayEmpty
						>
							<MenuItem selected>Select Bot</MenuItem>
							{selectBotMenu ? selectBotMenu : <MenuItem></MenuItem>}
						</Select>
						{
							createRecruitmentErrors.bot && <div style={{ color: 'red', fontSize: 14, marginLeft: 24 }}>Please select bot</div>
						}
					</div>
					<div className="button-div">
						<Button
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								margin: '0 10px'
							}}
							size="small"
							variant="contained"
							onClick={() => sendWorkflow()}
						>
							Create
						</Button>
						<Button
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								margin: '0 10px'
							}}
							size="small"
							variant="contained"
							onClick={onCloseModal}
						>
							Cancel
						</Button>
					</div>
				</Modal>
			</div>
			<div className="inner-div">
				<div className='top-icon-box'>
					<NotificationBell notification={notification} />
					<AccountMenu startTutorial={startTutorial} />
				</div>
			</div>
			<div className="userListTitleContainer" style={{ paddingTop: '20px' }}>
				<div className="inner-div-details">
					&nbsp;
				</div>
				<div className="search-box" style={{ width: "55vw" }}>
					<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
				</div>
				<div>
					<Button
						className="inner-div-btn dashboard-first-step"
						id="testNewDriveFlow-selector-1"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							textTransform: "capitalize",
							borderRadius: '25px',
							padding: '8px 20px',
							position: 'relative',
							zIndex: currentWalkthroughStep === 1 ? 1000 : 1 
						}}
						size="small"
						variant="contained"
						endIcon={<AddIcon color="white" />}
						onClick={onOpenAddNewDrive}
					>
						ADD NEW
					</Button>
				</div>
			</div>
			<div style={{ height: 'max-content', padding: '10px', background: '#fff', borderRadius: '25px', overflow: 'hidden', minHeight: '500px' }}>
				<DataGrid
					sortingOrder={['desc', 'asc']}
					components={{ NoRowsOverlay }}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => requestSearch(''),
						}
					}}
					rows={!isOpen ? workflows :
						[{
							id: 0,
							caseName: "Finance Associate",
							workflowId: "1",
							invitedCount: 500,
							totalCount: 3000,
							startedCount: 2595,
							awaitApprovalCount: 0,
							underReviewCount: 2500,
							reviewedCount: 0
						}]}
					disableSelectionOnClick
					columns={columns}
					checkboxSelection={userRole === 'Admin'}
					getRowClassName={() =>
						`rowOnHover`
					}
					onRowClick={() => { }}
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel);
					}}
					selectionModel={selectionModel}
					autoHeight
					pageSize={pageSize}
					loading={loading}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="dataGridScroll"
				/>
			</div>
			<AddNewDrive open={addNewDrive} onClose={onCloseAddNewDrive} setSnackErr={setSnackErr} change={change} handleSnackClick={handleSnackClick} setChange={setChange} companyName={companyName} selectBotMenu={convo2} currentWalkthroughStep={currentWalkthroughStep} updateCurrentWalkthroughStep={updateCurrentWalkthroughStep} />
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErr ? snackErr : ''}
				</Alert>
			</Snackbar>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					Updated Name Successfully
				</Alert>
			</Snackbar>
			{
				currentWalkthroughStep === 1 ? (
					<WalkthroughOverlay
						// onClickSkip={() => updateCurrentWalkthroughStep(2)}
						onClickExit={() => updateCurrentWalkthroughStep(0)}
						currentWalkthroughStep={currentWalkthroughStep}
					/>
				) : ''
			}
			<WalkthroughWelcome
				modalOpen={walkthroughWelcomeModal}
				onClose={() => setWalkthroughWelcomeModal(false)}
				updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	companyBots: getCompanyBots(),
	rerender: getRerender(),
	userPlan: getUserPlan(),
	userName: getUserName(),
	userRole: getUserRole(),
	saasPlanDetails: getSaasPlanDetails(),
	companyFeatures: getCompanyFeatures(),
	currentWalkthroughStep: getCurrentWalkthroughStep()
});


const mapDispatchToProps = (dispatch) => ({
	setWorkflowFunnelData: (data) => dispatch(setWorkFlowFunnelDataRequest(data)),
	setReportModel: (data) => dispatch(setReportModelRequest(data)),
	fetchCompanyBots: (data) => dispatch(fetchCompanyBotsRequest(data)),
	resetRerender: () => dispatch(resetRerenderRequest()),
	editDriveName: (data) => dispatch(editDriveNameRequest(data)),
	setDashboardLogo: (data) => dispatch(setDashboardLogoRequest(data)),
	fetchMyDetails: (data) => dispatch(fetchMyDetailsRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

Workflow.propTypes = {
	userName: PropTypes.string,
	setShouldShowTour: PropTypes.func,
	setWorkflowFunnelData: PropTypes.func,
	shouldShowTour: PropTypes.bool,
	companyBots: PropTypes.array,
	fetchCompanyBots: PropTypes.func,
	editDriveName: PropTypes.func,
	rerender: PropTypes.number,
	resetRerender: PropTypes.func,
	setReportModel: PropTypes.func,
	notification: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
