import React, { useState, useEffect, useRef } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import D3Funnel from 'd3-funnel';
import RightArrow from '../../assets/Icons/right.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import { useLocation, useHistory } from 'react-router-dom';

import AccountMenu from "../accountMenu/AccountMenu";
import axios from "../../axios";
import NotificationBell from '../notificationBell/notificationBell';
import { addNotification as addNotificationRequest, getCandidatesByType as getCandidatesByTypeRequest, updateUserSettings as updateUserSettingsRequest, createWorkflowJobRole as createWorkflowJobRoleRequest, updateJobRoleDetails as updateJobRoleDetailsRequest, fetchPreScreeningBots as fetchPreScreeningBotsRequest, updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest } from '../../actions/app';
import { getCandidatesList, getCompanyFeatures, getCreateJobLoading, getCurrentWalkthroughStep, getJobRoleDetails, getPreScreeningBots, getUserEmail, getUserName } from '../../selectors/app';

import emptyRowImg from '../../assets/emptyrow2.png';
import CopyIcon from '../../assets/copy-icon.png';
import FeatureLockedWhiteIcon from '../../assets/feature-locked-white.png';
import InfoIcon from '../../assets/info-roles.png';
import {
    updateTrackerInfo as updateTrackerInfoRequest
} from '../../actions/app'
import '../../styles/QuickMenu.scss';
import '../../styles/createJobOpening.scss';
import './QuickMenu.css';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import Select from '../../components/common/Select';
import BookDemoModal from '../../components/common/BookDemoModal';
import FeatureLockedModal from '../../components/common/FeatureLockedModal';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';
import WalkthroughOverlay from '../../components/common/WalkthroughOverlay';

const QuickMenu = (props) => {
    const { addNotification, updateUserSettings, updateTrackerInfo,createWorkflowJobRole, updateJobRoleDetails, jobRoleDetails, createJobLoading, preScreeningBots, fetchPreScreeningBots, userName, userEmail, companyFeatures, currentWalkthroughStep, updateCurrentWalkthroughStep } = props
    const fin = useRef()
    const [workflow, setWorkflow] = useState()
    const [isLoading, setLoading] = useState(true)
    const [finalData, setFinalData] = useState()
    const [funnelData, setFunnelData] = useState()
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const [newJobModal, setNewJobModal] = useState(false);
    const [jobRoleName, setJobRoleName] = useState('');
    const [jobRoleDesc, setJobRoleDesc] = useState('');
    const [jobRoleFile, setJobRoleFile] = useState();
    const [jobRoleFilePath, setJobRoleFilePath] = useState('');
    const [jobRoleStatus, setJobRoleStatus] = useState('create');
    const [jobRolePsBot, setJobRolePsBot] = useState({});
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackErrMsg, setSnackErrMsg] = useState('');
    const [snackSuccessMsg, setSnackSuccessMsg] = useState('');
    const [showPreScreeningBots, setShowPreScreeningBots] = useState(false);
    const [requestDemoModal, setRequestDemoModal] = useState(false);
    const [featureLockedModal, setFeatureLockedModal] = useState(false);

    const location = useLocation()
    const history = useHistory();
    //  console.log(location.state)

    const onOpenNewJobModal = () => {
        updateTrackerInfo({
                        heading: "CLICK_CREATE_JOB_OPENING",
                        rawRequest : JSON.stringify({}),
                        detail : currentWalkthroughStep>0?"Walkthrough":"Normal"
                    })
        setFeatureLockedModal(true)
       /* if (checkCompanyFeatureAccess(companyFeatures, 'createJobOpening') === 1) {
            setFeatureLockedModal(true);
        } else {
            if (jobRoleDetails.name) {
                setJobRoleStatus('edit');
            } else {
                setJobRoleStatus('create');
            }
            setNewJobModal(true);
        }*/
    }

    const onCloseNewJobModal = () => {
        if (!jobRoleDetails.name) {
            setJobRoleName('');
            setJobRoleDesc('');
            setJobRoleFilePath('');
            setJobRoleFile();
            setJobRolePsBot({});
            setShowPreScreeningBots(false)
        }
        setNewJobModal(false);
    }

    useEffect(() => {
        if (!workflow) return;

        let temp = []
        workflow.candidates.map((val) => {
            let x = { ID: val.id, name: val.name, email: val.email, status: val.status }
            temp.push(x);
        })
        setCSVData(temp);
    }, [workflow])

    const getScore = function (params) {
        var score = 0.0;
        var index = 0
        try {
            if (params.test && params.test.result && params.test.result.hasOwnProperty('total'))
                score = params.test.result.total
            else {
                if (params.productId.toLowerCase().includes('sale')) {
                    if (params.test.result.dab)
                        score += 0.2 * params.test.result.dab
                    if (params.test.result.lpg)
                        score += 0.2 * params.test.result.lpg
                    if (params.test.result.stk)
                        score += 0.1 * params.test.result.stk
                    if (params.test.result.ema)
                        score += 0.5 * params.test.result.ema
                }
                else {
                    for (let keys in params.row.test.result) {
                        score += params.test.result[keys]
                        index += 1
                    }
                    if (index > 0) score = score / index
                }
            }
            if (score > 0) score *= 100
        }
        catch {
            // console.log("Error in scores");
        }

        return score.toFixed();
    }

    useEffect(() => {
        if (finalData)
            setLoading(false)
    }, [finalData])

    useEffect(() => {
        ///console.log(currentWalkthroughStep)
        axios.get(("/workflow/" + location.state.workflowId))
            .then((res) => {
                // console.log(res.data)
                localStorage.setItem('driveInvitedCount', res.data.invite.length)
                localStorage.setItem('driveSubmittedCount', res.data.review.length)
                addNotification({ driveInvitedCount: res.data.invite.length, driveSubmittedCount: res.data.review.length });
                setWorkflow(res.data)
                localStorage.setItem('settings', JSON.stringify(res.data.settings));
                updateUserSettings(res.data.settings);
                if (res.data.jobRole && res.data.jobRole.name) {
                    updateJobRoleDetails(res.data.jobRole);
                    setJobRoleStatus('edit');
                } else {
                    const jobRole = {
                        name: '',
                        desc: ''
                    }
                    updateJobRoleDetails(jobRole);
                }
                setFinalData([
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-invited"></div>
                        </td>
                        <td className="quickmenu__card-td">Invited</td>
                        <td className="quickmenu__card-td">{res.data.candidates.length}</td>
                        {/* <td className="quickmenu__card-td">
                            <Link to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "applicants" } }} id="quickViewFlow-selector-2">View full list <ArrowForwardIcon style={{ color: "#4FCA64" }} /></Link>
                        </td> */}
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-submitted"></div>
                        </td>
                        <td className="quickmenu__card-td">Submitted</td>
                        <td className="quickmenu__card-td">{res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length}</td>
                        {/* <td className="quickmenu__card-td"><Link to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "reviewApplicants" } }} id="quickViewFlow-selector-3">View full list <ArrowForwardIcon style={{ color: "#54C5EB" }} /></Link></td> */}
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-shortlisted"></div>
                        </td>
                        <td className="quickmenu__card-td">Shortlisted</td>
                        <td className="quickmenu__card-td">{res.data.shortlisted.length}</td>
                        {/* <td className="quickmenu__card-td"><Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "finalised" } }} id="quickViewFlow-selector-4">View full list <ArrowForwardIcon style={{ color: "#FFB74A" }} /></Link></td> */}
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-hired"></div>
                        </td>
                        <td className="quickmenu__card-td">Hired</td>
                        <td className="quickmenu__card-td">{res.data.hired.length}</td>
                        {/* <td><Link style={{marginLeft:'10px',textDecoration:"none", width:"100%", color:"black", fontSize: '16px', fontWeight: '600'}} to={{pathname:"/finalized", state:{workflowId: location.state.workflowId, workflowName:location.state.caseName, sidebar:"quickLook"}}}>View full list <ArrowForwardIcon style={{height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#22242C`}}/></Link></td> */}
                    </tr>])
                const dataf = [
                    { label: 'Invited', value: res.data.candidates.length > 0 ? 100 : 0 },
                    { label: 'Submitted', value: parseInt((((res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length) * 100) / res.data.candidates.length), 10) },
                    { label: 'Shortlisted', value: parseInt(((res.data.shortlisted.length * 100) / res.data.candidates.length), 10) },
                    { label: 'Hired', value: parseInt(((res.data.hired.length * 100) / res.data.candidates.length), 10) },
                ];
                const optionf = {
                    block: {
                        dynamicHeight: true,
                        dynamicSlope: false,
                        minHeight: res.data.candidates.length > 0 ? 10 : 30,
                        fill: {
                            type: 'gradient',
                            scale: ['#4FCA64', '#54C5EB', '#FFB74A', '#22242C']
                        }
                    },
                    tooltip: {
                        enabled: true,
                        format: '{l} : {f}%'
                    },
                    label: { enabled: false },
                    chart: {
                        curve: {
                            height: 10,
                            enabled: true
                        },
                        totalCount: 200,
                        inverted: false
                    }
                };
                setFunnelData(<div className="chart" ref={fin} id='chart1' style={{ height: "150px" }}></div>)
                const chart = new D3Funnel(fin.current)
                chart.draw(dataf, optionf)
            })
            .catch((err) => {
                console.log(err)
                redirectUnauthorisedUser(err, history);
            })


        let datax = {
            status: "shortlisted",
            id: location.state.workflowId
        }
        let datay = {
            status: "candidates",
            id: location.state.workflowId
        }
        axios.post("/gettype", datax)
            .then((res) => {
                setRows2(res.data)
                // setProgress(res.)
            })
            .catch(err => {
                redirectUnauthorisedUser(err, history);
            })
        axios.post("/gettype", datay)
            .then((res) => {
                if (res.data.length > 3)
                    setRows1(res.data)
                else
                    setRows1(res.data)
                // setProgress(res.)
            })
            .catch(err => {
                redirectUnauthorisedUser(err, history);
            })
        fetchPreScreeningBots();
    }, []);


    const [open, setOpen] = useState(false)
    const onOpenModal = () => {
        updateTrackerInfo({
                        heading: "CLICK_CHATBOT_LINK",
                        rawRequest : JSON.stringify({}),
                        detail : currentWalkthroughStep>0?"Walkthrough":"Normal"
                    })
        if(currentWalkthroughStep==9)
            updateCurrentWalkthroughStep(0)
        setOpen(true);}
    const onCloseModal = () => setOpen(false);

    /*const lables = [
        'Invited',
        'Submited',
        'Shortlisted',
        'Hired',
      ];

    const data = [300, 50, 100, 200, 10];

      const colour = ["#4FCA64", "#54C5EB", "#FFB74A", "#22242C"]*/

    /*function renderData(){
        let finalData = []
        for(let i=0; i<lables.length;i++){
            finalData.push(<tr>
                <td>
                    <div style={{width: "15px", height: "15px", backgroundColor: `${colour[i]}`, borderRadius: "50%"}}></div>
                </td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"23px", "color":"#404040"}}>{lables[i]}</td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"23px", "color":"#404040"}}>{data[i]}</td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"bold","fontSize":"18px","lineHeight":"23px", "color":"#273B4A"}}><a>View Full List <ArrowForwardIcon style={{height: "18px", width: "20px", color: `${colour[i]}`}}/></Link></td>
            </tr>)
        }
        return finalData;
    }
*/
    function renderData() {
        return finalData;
    }

    function renderFunnelData() {
        return funnelData;
    }

    const getCSV = () => {
        /*axios.get('/workflow/csv/'+location.state.workflowId).then((res)=>{
          console.log(res)
        }).catch((err)=>{
          console.log(err)
        })*/
        var newwindow = window.open(process.env.REACT_APP_BASE_SERVER_URL_FULL + '/workflow/csv/' + location.state.workflowId)
        // var newwindow= window.open('http://localhost:5000/Linkpi/workflow/csv/'+location.state.workflowId)
        newwindow.focus();
        newwindow.onblur = function () { newwindow.close(); };
    }

    function renderFunnelData() {
        return funnelData;
    }

    const handleJobRoleFileChange = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setJobRoleFile(reader.result);
            setJobRoleFilePath(e.target.files[0].name);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const openPreview = (e) =>{
        updateTrackerInfo({
                        heading: "CLICK_CHATBOT_PREVIEW",
                        rawRequest : JSON.stringify({}),
                        detail : workflow._id
                    })
        //console.log(process.env.REACT_APP_BASE_URL + "/demorush/" + workflow._id)
       const x = window.open(process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id, '_blank')
    }
    const onCreateJobRole = () => {
        if (jobRoleName === '') {
            setSnackErrMsg('Enter valid Job Role Name');
            handleErrSnackClick();
        } else if (!jobRoleFilePath) {
            setSnackErrMsg('Please Upload Job Description');
            handleErrSnackClick();
        } else if (showPreScreeningBots && !jobRolePsBot.name) {
            setSnackErrMsg('Please select Pre screening bot');
            handleErrSnackClick();
        } else {
            const formData = new FormData();
            if (jobRoleFile) {
                fetch(jobRoleFile)
                    .then(res => res.blob())
                    .then(blob => {
                        const dname = location.state.workflowId + 'jobRole';
                        const file = new File([blob], dname + '.pdf', { type: "application/pdf" });
                        formData.append('pdf', file);
                        formData.append('workflowId', location.state.workflowId);
                        formData.append('name', jobRoleName);
                        formData.append('desc', jobRoleDesc);
                        if (showPreScreeningBots) {
                            formData.append('botId', jobRolePsBot._id)
                        }

                        createWorkflowJobRole({ formData, history });
                    });
            } else {
                formData.append('workflowId', location.state.workflowId);
                formData.append('name', jobRoleName);
                formData.append('desc', jobRoleDesc);
                if (showPreScreeningBots) {
                    formData.append('botId', jobRolePsBot._id)
                }

                createWorkflowJobRole({ formData, history });
            }
        }
    }

    useEffect(() => {
        if (jobRoleDetails.name && !createJobLoading) {
            setJobRoleDesc(jobRoleDetails.desc);
            setJobRoleName(jobRoleDetails.name);
            setJobRoleFilePath(jobRoleDetails.jobRolePdf);
            if (jobRoleDetails.botId) {
                setShowPreScreeningBots(true);
                let selectedBot;
                preScreeningBots.forEach((psb) => {
                    if (psb._id === jobRoleDetails.botId) {
                        selectedBot = { ...psb };
                    }
                });
                setJobRolePsBot({ ...selectedBot });
            } else {
                setShowPreScreeningBots(false);
                setJobRolePsBot({});
            }
        } else {
            setJobRoleName('');
            setJobRoleDesc('');
            setJobRoleFilePath('');
            setJobRoleFile();
            setJobRolePsBot({});
            setShowPreScreeningBots(false);
        }
    }, [jobRoleDetails]);

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleSuccessSnackClick = () => {
        setSuccessSnackOpen(true);
    };

    const handleSuccessSnackClose = () => {
        setSuccessSnackOpen(false);
    };

    const handleErrSnackClose = (event, reason) => {
        setErrSnackOpen(false);
    };

    const onClickCopyLink = () => {
        navigator.clipboard.writeText(`https://hirequotient.com/applyforjobs?workflow=${location.state.workflowId}`);
        handleSuccessSnackClick();
        setSnackSuccessMsg('The link has been copied');
    }

    const handlePreScreeningCheck = (e) => {
        if (e.target.checked) {
            setShowPreScreeningBots(true);
        } else {
            setShowPreScreeningBots(false);
        }
    }

    const onChangePreScreeningBot = (val) => {
        let selectedBot;
        preScreeningBots.forEach((psb) => {
            if (psb._id === val.value) {
                selectedBot = { ...psb };
            }
        });
        setJobRolePsBot({ ...selectedBot });
    }

    const onClickAnalytics = () => {
        updateTrackerInfo({
                        heading: "CLICK_ANALYTICS",
                        rawRequest : JSON.stringify({}),
                        detail : "Analytics Page"
                    })
        if (checkCompanyFeatureAccess(companyFeatures, 'analyticsSidebar') === 1) {
            setFeatureLockedModal(true);
        } else {
            history.push({
                pathname: "/analytics",
                state: {
                    workflowId: location.state.workflowId,
                    workflowName: location.state.workflowName,
                    sidebar: "analytics"
                }
            })
        }
    }

    return (
        <div className='mainbar'>
            <div className="notificationbar">
                <div className='notificationbar__icon-box'>
                    <NotificationBell />
                    <AccountMenu />
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
                <div className="modal__heading">
                    Chatbot Link
                </div>
                <p className="modal__heading-secondary">
                    {isLoading ? '' : process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id}
                </p>
                <div className="modal__btnbox">
                    <Button
                        id="quickViewFlow-selector-9"
                        className="btn-primary"
                        onClick={() => {
                            onCloseModal()
                        }}
                        size="small"
                        variant="contained"
                    >
                        Okay
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                            navigator.clipboard.writeText(isLoading ? '' : process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id)
                            onCloseModal()
                        }}>
                        Copy
                    </Button>
                </div>
            </Modal>
            <BookDemoModal
                requestDemoModal={requestDemoModal}
                setRequestDemoModal={setRequestDemoModal}
                userName={userName}
                userEmail={userEmail}
            />
            <div className="actionbar">
                <div className="actionbar__details">
                    <h2>Quick Overview</h2>
                    <p style={{ wordBreak: 'break-all' }}>{!isLoading ? workflow.name : null}</p>
                </div>
                <div className="actionbar__btn-box" style={{ whiteSpace: 'nowrap', display: 'flex' }}>
                    <div>
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            id="quickViewFlow-selector-7"
                            onClick={onClickAnalytics}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            Analytics
                            {
                                checkCompanyFeatureAccess(companyFeatures, 'analyticsSidebar') === 1 ? (
                                    <div style={{ marginLeft: 15, cursor: 'pointer', display: 'flex' }} onClick={() => setFeatureLockedModal(true)}>
                                        <img style={{ width: 16 }} src={FeatureLockedWhiteIcon} alt='locked feature' />
                                    </div>
                                ) : ''
                            }
                        </Button>
                    </div>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        id="quickViewFlow-selector-8"
                        onClick = {()=>onOpenModal()}
                        style={{ position: 'relative', zIndex: currentWalkthroughStep === 9 ? 1000 : 0 }}
                    >
                        Chatbot Link
                    </Button>

                    {/* <Button
                        id="quickViewFlow-selector-10"
                        className="btn-primary"
                        size="small"
                        variant="contained"
                    >
                        {CSVData ? <CSVLink filename="Data.csv" data={CSVData} target="_blank">Download Report</CSVLink> : ''}
                    </Button> */}
                    <div>
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            onClick={onOpenNewJobModal}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            {jobRoleStatus === 'create' ? 'Create' : 'Edit'} Job Opening
                            {
                                checkCompanyFeatureAccess(companyFeatures, 'analyticsSidebar') === 1 ? (
                                    <div style={{ marginLeft: 15, cursor: 'pointer', display: 'flex' }} onClick={() => setFeatureLockedModal(true)}>
                                        <img style={{ width: 16 }} src={FeatureLockedWhiteIcon} alt='locked feature' />
                                    </div>
                                ) : ''
                            }
                        </Button>
                    </div>
                </div>
            </div>
            <div className="quickmenu__container">
                <div className="quickmenu__card-container quickmenu__card-container-flex">
                    <div className="quickmenu__card-leftbox">
                        <div className="quickmenu__card-headingbox">
                            <h3 className="quickmenu__card-heading">Overview</h3>
                        </div>
                        {/* {
                            workflow && (
                                <div className="quickmenu__card-chartbox">
                                    <Funnel
                                        width={400}
                                        invited={workflow.candidates.length}
                                        submitted={workflow.review.length}
                                        shortlisted={workflow.shortlisted.length}
                                        hired={workflow.hired.length}
                                    />
                                </div>
                            )
                        } */}
                        {renderFunnelData()}
                    </div>
                    <div className="quickmenu__card-rightbox">
                        <div>
                            <table>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">All Applicants</h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">Total applicants : {rows1.length}</p>
                        </div>
                        {/* <div>
                            <Link className="quickmenu__card-link" to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "applicants" } }} id="quickViewFlow-selector-5">
                                <h3 className="quickmenu__card-fulllist">View full list <img src={RightArrow} alt="" /></h3>
                            </Link>
                        </div> */}
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='quickmenu__table-head' align="left"><span>ID</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Name</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Email</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Status</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows1.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto"
                                                            }}
                                                        />
                                                        <p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1.length < 3 ? (
                                                rows1.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : (row.status === 'In Progress' ? 'Opened (0% completed)' : (isNaN(row.status) ? row.status : `${row.status}% completed`))}</span></TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                rows1.slice(0, 3).map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : (row.status === 'In Progress' ? 'Opened (0% completed)' : (isNaN(row.status) ? row.status : `${row.status}% completed`))}</span></TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">Shortlisted</h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">Shortlisted candidates : {rows2.length}</p>
                        </div>
                        {/* <div>
                            <Link className="quickmenu__card-link" to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "finalised" } }} id="quickViewFlow-selector-6">
                                <h3 className="quickmenu__card-fulllist">View full list <img src={RightArrow} alt="" /></h3>
                            </Link>
                        </div> */}
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='quickmenu__table-head' align="left"><span>ID</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Name</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Email</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Status</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows2.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto"
                                                            }}
                                                        />
                                                        <p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows2.length < 3 ? (
                                                rows2.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : (row.status === 'In Progress' ? 'Opened (0% completed)' : (isNaN(row.status) ? row.status : `${row.status}% completed`))}</span></TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                rows2.slice(0, 3).map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : (row.status === 'In Progress' ? 'Opened (0% completed)' : (isNaN(row.status) ? row.status : `${row.status}% completed`))}</span></TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <Modal open={newJobModal} onClose={onCloseNewJobModal}>
                <div className='create-job-opening' style={{ padding: '20px 40px', width: 700 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                        <div className='back-btn'>
                            <div></div>
                        </div>
                        <div style={{ fontSize: 24, fontWeight: '700', marginTop: 35 }}>Create new job</div>
                    </div>
                    <div style={{ fontSize: 16, color: '#6b6b6b', fontWeight: '600' }}>Name or Role*</div>
                    <div style={{ minWidth: 350 }}>
                        <input value={jobRoleName} onChange={(e) => setJobRoleName(e.target.value)} style={{ fontSize: 16, fontFamily: 'inherit', padding: '8px 16px', outline: 'none', border: 'none', background: '#f2f2f2', borderRadius: 8, marginTop: 10, width: '100%', boxSizing: 'border-box' }} type='text' />
                    </div>
                    <div style={{ fontSize: 16, color: '#6b6b6b', fontWeight: '600', marginTop: 20 }}>Brief description</div>
                    <div style={{ marginTop: 10, paddingBottom: 25, borderBottom: '1px solid #c4c4c4', minWidth: 350 }}>
                        <textarea rows="5" onChange={(e) => setJobRoleDesc(e.target.value)} style={{ fontSize: 16, fontFamily: 'inherit', padding: '8px 16px', outline: 'none', border: 'none', background: '#f2f2f2', borderRadius: 8, width: '100%', boxSizing: 'border-box' }}>{jobRoleDesc}</textarea>
                    </div>
                    {
                        jobRoleStatus === 'edit' ? '' : (
                            <div style={{ fontSize: 16, color: '#6b6b6b', fontWeight: '600', marginTop: 15 }}>Upload JD*</div>
                        )
                    }
                    {
                        jobRoleStatus === 'edit' ? (
                            <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>Uploaded JD</div>
                                <div style={{ position: 'relative', background: '#f2f2f2', borderRadius: 8, height: 40, border: 'none', marginLeft: 20, width: 210, justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '0 20px', overflow: 'hidden' }}>
                                    <div style={{ color: '#a8a8a8' }}>{jobRoleFilePath}</div>
                                    <div style={{ height: 40, background: '#cdcdcd', borderRadius: 10, padding: '0 20px', display: 'flex', alignItems: 'center', position: 'absolute', right: 0 }}>Change</div>
                                    <input style={{ outline: 'none', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, opacity: 0, boxSizing: 'border-box' }} type='file' onChange={handleJobRoleFileChange} accept="application/pdf" />
                                </div>
                            </div>
                        ) : (
                            <div style={{ marginTop: 15, background: '#f2f2f2', borderRadius: 8, position: 'relative', height: 40, display: 'flex', alignItems: 'center', padding: '0 15px', justifyContent: 'space-between' }}>
                                <div style={{ color: '#a8a8a8' }}>{jobRoleFilePath}</div>
                                <input style={{ outline: 'none', border: 'none', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, opacity: 0 }} type='file' onChange={handleJobRoleFileChange} accept="application/pdf" />
                                <AddCircleOutlineIcon />
                            </div>
                        )
                    }
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                        <div class="modal-checkbox">
                            <label>Add Pre-screening stage<input type="checkbox" name="preScreening"
                                value="preScreening" onClick={handlePreScreeningCheck} checked={showPreScreeningBots} /><span class="checkmark"></span></label>
                        </div>
                        {/* <div style={{ marginLeft: 20, display: 'flex' }}>
                            <Tooltip title={'HireQuotient Pte Ltd Mail - Invitation to HireQuotient Online Assessment'}>
                                <img src={InfoIcon} alt='' />
                            </Tooltip>
                        </div> */}
                    </div>
                    {
                        showPreScreeningBots ? (
                            <div>
                                <Select
                                    selectedValue={jobRolePsBot.name ? jobRolePsBot.name : 'Select pre-screening bots'}
                                    onChangeSelect={onChangePreScreeningBot}
                                    dropdownAddItem={{ text: 'Add Pre-screening bot', link: '/pre-screening/dashboard' }}
                                    dropdownData={
                                        preScreeningBots.map((psb) => {
                                            return { value: psb._id, label: psb.name }
                                        })
                                    }
                                    style={{
                                        root: {
                                            borderRadius: 10,
                                            border: '1px solid #c4c4c4',
                                            position: 'relative',
                                            zIndex: 10,
                                            width: 300,
                                            flex: 10,
                                            marginTop: '30px'
                                        },
                                        wrapper: {
                                            borderBottom: 'none',
                                            padding: '0 15px',
                                            textAlign: 'left'
                                        },
                                        dropdown: {
                                            zIndex: 1
                                        }
                                    }}
                                />
                            </div>
                        ) : ''
                    }
                    <div style={{ textAlign: 'center', position: 'relative', marginTop: 50 }}>
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            onClick={onCreateJobRole}
                            disabled={createJobLoading}
                        >
                            {jobRoleStatus === 'create' ? 'Create link' : 'Save'}
                        </Button>
                        {
                            createJobLoading ? (
                                <div className='create-job-loader'></div>
                            ) : ''
                        }
                    </div>
                    {
                        jobRoleDetails.name ? (
                            <div style={{ marginTop: 25, display: 'flex', justifyContent: 'space-between', background: '#f2f2f2', borderRadius: 8, padding: '8px 24px', alignItems: 'center' }}>
                                <div style={{ marginRight: 20, color: '#a8a8a8' }}>{`https://hirequotient.com/applyforjobs?workflow=${location.state.workflowId}`}</div>
                                <Tooltip title='Copy link'>
                                    <div className='copy-jd-link' style={{ cursor: 'pointer' }} onClick={onClickCopyLink}>
                                        <img src={CopyIcon} alt='' />
                                    </div>
                                </Tooltip>
                            </div>
                        ) : ''
                    }
                </div>
            </Modal>
            <FeatureLockedModal
                modalOpen={featureLockedModal}
                onClose={() => setFeatureLockedModal(false)}
            />
            <Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose}>
                <Alert onClose={handleSuccessSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {snackSuccessMsg ? snackSuccessMsg : ''}
                </Alert>
            </Snackbar>
            <Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose}>
                <Alert onClose={handleErrSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {snackErrMsg ? snackErrMsg : ''}
                </Alert>
            </Snackbar>
            {
				currentWalkthroughStep === 9 ? (
					<WalkthroughOverlay
						onClickExit={() => updateCurrentWalkthroughStep(0)}
						currentWalkthroughStep={currentWalkthroughStep}
					/>
				) : ''
			}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    candidates: getCandidatesList(),
    jobRoleDetails: getJobRoleDetails(),
    createJobLoading: getCreateJobLoading(),
    preScreeningBots: getPreScreeningBots(),
    userName: getUserName(),
    userEmail: getUserEmail(),
    companyFeatures: getCompanyFeatures(),
    currentWalkthroughStep: getCurrentWalkthroughStep()
});

const mapDispatchToProps = (dispatch) => ({
    addNotification: (data) => dispatch(addNotificationRequest(data)),
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
    updateUserSettings: (data) => dispatch(updateUserSettingsRequest(data)),
    createWorkflowJobRole: (data) => dispatch(createWorkflowJobRoleRequest(data)),
    updateJobRoleDetails: (data) => dispatch(updateJobRoleDetailsRequest(data)),
    fetchPreScreeningBots: (data) => dispatch(fetchPreScreeningBotsRequest(data)),
    updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data)),
    updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickMenu);