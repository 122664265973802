import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from 'react-responsive-modal';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import axios from '../../axios';
import EmptyTab from "../reports/EmptyTab";
import InterviewerGuide from './InterviewerGuide';
import PdfReport from '../PdfReport';
import ReportTab from '../reports/ReportTab';
import VideoCard from '../../pages/videoSubmission/videoCard';
import { getCompanyFeatures } from '../../selectors/app';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';

import downloadactiveicon from '../../assets/downloadactive.png';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';
import FeatureLockedWrapper from './FeatureLockedWrapper';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const AnalyseModal = (props) => {
    const { open, onClose, chat, changeStatus, isOpen, companyFeatures } = props;

    const history = useHistory();
    const location = useLocation();

    const [currentReportTab, setCurrentReportTab] = useState(1);
    const [expanded, setExpanded] = useState("");
    const [videoCardData, setVideoCardData] = useState([]);


    const getQuestionText = (row, cond) => {
        let txt = ''

        if (Array.isArray(row.ques)) {
            let ele = row.ques[row.ques.length - 1].split('***::')
            txt = ele[ele.length - 1]
        }
        else if (typeof (row.ques) == 'string') {
            txt = row.ques.split('***::')[row.ques.split('***::').length - 1]
        }

        if (!cond && txt.length > 200) {
            txt = txt.substring(0, 200) + '...'
        }
        return txt.replaceAll(';;', ' ').replaceAll('::', ' ')
    }


    const getCandVideo = (key) => {
        axios.post('/cand-video', { key }).then((res) => {
            window.open(res.data.signedUrl)
        }).catch((err) => {
            redirectUnauthorisedUser(err, history);
        })
    }

    useEffect(() => {
        if (!chat.test) return;

        async function addVideoCardData() {
            let tempArr = [];

            for (let i = 0; i < chat.test.chat.length; i++) {
                let val = chat.test.chat[i];

                if (val.ans && !val.ans.Workbook && val.ans.includes('.mp4')) {
                    let res = await axios.post('/cand-video', { key: val.ans })
                    let tempData = { ques: val.ques, scores: val.scores, src: res.data.signedUrl, cid: val.cid, notes: val.notes };
                    tempArr.push(tempData);
                }
            }

            setVideoCardData(tempArr);
        }
        addVideoCardData();
    }, [chat]);

    useEffect(() => {
       setCurrentReportTab(1);
    }, [companyFeatures]);

    return (
        <Modal open={open} onClose={onClose} center classNames={{ modal: 'customModalChats', closeIcon: 'closeIcon-large' }} styles={{ modal: { width: 900 } }}>
            <div className="container-team" style={{ padding: "0 20px 20px", margin: 0, height: 625 }}>
                <div className="heading-team">
                    <div>
                        <span style={{ fontSize: "25px", fontWeight: "600", color: "#479BD2" }}>{chat.name}</span>
                        {chat.test ?
                            <span style={{ borderLeft: "1px solid rgb(150, 150, 150)", marginLeft: "10px", paddingLeft: "10px", fontSize: "16px", fontWeight: "500" }}>
                                Time taken: {Math.floor(((Math.abs(new Date(chat.test.endDate) - new Date(chat.test.createdDate))) / 1000) / 60)} mins
                            </span>
                            : ""}
                    </div>
                    <div>
                        <Tooltip title="Shortlist"><IconButton><CheckCircleOutlineOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Shortlisted', [chat]) }} className="check-btn" /></IconButton></Tooltip>
                        <Tooltip title="Reject"><IconButton><CancelOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Rejected', [chat]) }} className="cancel-btn" /></IconButton></Tooltip>
                        <Tooltip title="Keep in view"><IconButton><RemoveRedEyeOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Kept In View', [chat]) }} className="visibility-btn" /></IconButton></Tooltip>
                    </div>
                </div>
                <div>
                    <div className="report-tabs-container">
                        <div className="report-tabs dashboard-forth-step">
                           
                            {
                                companyFeatures['videoSubmissions'] ? (
                                    <div
                                        className={'active-report-tab'}
                                        onClick={() => setCurrentReportTab(1)}>
                                        Video submissions
                                    </div>
                                ) : ''
                            }
                            
                        </div>

                    
                    </div>
                </div>
                <div className="scrollblue" style={{ marginTop: "20px", maxHeight: "450px", overflowY: "auto", overflowX: "hidden", padding: "10px 10px 10px 20px" }}>
                    
                    {
                        
                            chat?.test?.chat?.length > 0 ?
                                (<div>
                                    <div className="videobox-wrapper">
                                        {videoCardData.length == 0 ? 'No Video' : videoCardData.map((val) => <VideoCard ques={val.ques} src={val.src} scores={val.scores?val.scores:{}} cid={val.cid} notes={val.notes} candidateData={chat} />)}
                                    </div>
                                </div>)
                                : <EmptyTab text={"Couldn't find video submission for this candidate"} />
                    }
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = createStructuredSelector({
    companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseModal);
