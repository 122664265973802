import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import axios from '../../axios';
import { assessmentGuidelines } from '../../constants/app';

import '../../styles/testGuidelines.scss';
import styles from '../../styles/UserDetails.module.css';

import privacyPolicy from '../../assets/PrivacyPolicyHQ 26Nov2021.pdf'

const Dashboard = () => {
	let history = useHistory();
	const location = useLocation();
	const { comp, workflowId, user_id } = useParams();

	const [checked, setChecked] = useState(false);
	const [guidelines, setGuidelines] = useState([...assessmentGuidelines]);
	const [assessmentWelcomeText, setAssessmentWelcomeText] = useState('Welcome to HireQuotient');

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [resumeStat, setResumeStat] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [isDisabled, setdisable] = useState(true);
	const [stateobj, setstateobj] = useState({});

	const postoptions = {
		user_id: user_id,
		comp: comp,
		workflowId: workflowId
	}

	const onGoAhead = () => {
		if (!completed)
			history.push({
				pathname: '/chatbox',
				state: stateobj
			});
	}

	useEffect(() => {
		if (stateobj)
			setdisable(false);
	}, [stateobj]);

	useEffect(() => {
		axios
			.post('/check-cases', postoptions)
			.then((res) => {
				if (res.data.auth) {
					if (res.data.completed)
						history.push({
							pathname: '/success',
							state: {
								code: user_id,
								wf: location.state.wf,
								wfName: location.state.wfName
							}
						})
					else {
						setName(res.data.details.name);
						setEmail(res.data.details.email);
						setResumeStat(res.data.hasPendingCases);
						setCompleted(res.data.completed);
						let productId;
						if (res.data.productId?.freeTrial) {
							if (res.data.productId.role === 'Project Manager') {
								productId = {
									isMegacase: false,
									isCustom: true,
									customId: 'ChatProjectFree'
								};
							} else if (res.data.productId.role === 'Sales and Marketing') {
								productId = {
									isMegacase: false,
									isCustom: true,
									customId: 'ChatSalesMFree'
								};
							} else if (res.data.productId.role === 'Associate consultant' || res.data.productId.role === 'Consultant') {
								productId = {
									isMegacase: false,
									isCustom: true,
									customId: 'ChatConsultFree'
								};
							}
						} else {
							productId = res.data.productId;
						}
						setstateobj({ id: productId, wf: workflowId, comp: comp, startT: res.data.startT, code: user_id, heading: res.data.title, prob: res.data.problem, resume: res.data.hasPendingCases, videoProctoring: res.data.videoProctoring, logo: res.data.logo, assessmentTime: res.data.assessmentTime, remainingTime: res.data.remainingTime, wfName: res.data.name });
						if (res.data.guidelines) {
							setGuidelines([...res.data.guidelines]);
						}
						if (res.data.assessmentWelcomeText) {
							setAssessmentWelcomeText(res.data.assessmentWelcomeText);
						}
					}
				}
				else {
					history.push('/invalid');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				history.push('/invalid');
				setIsLoading(false);
				console.log(err);
			});
	}, []);

	return (
		<div id="testGuidelinesPage">
			<div className='test-guidelines-header'>
				<div>
					<img style={{ height: "auto", width: "180px", padding: "1vh" }} src="/hq-logo.png" alt="logo" />
				</div>
				<div>
					<h3 style={{ color: '#fff', fontWeight: '400' }}>{email ? email : ''}</h3>
				</div>
			</div>
			<div className='test-guidelines-welcome'>
				{
					!isLoading ? (
						<h2>{assessmentWelcomeText}, {name ? name : ""}!</h2>
					) : ''
				}
			</div>
			<div className='test-guidelines-wrapper'>
				<div className={styles.paper}>
					<div className='test-guidelines-content'>
						<h2 style={{ padding: '0 40px', marginBottom: '10px' }}>Guidelines</h2>
						<div className='test-guidelines-content-scroll'>
							{
								!isLoading ? (
									<ul style={{ textAlign: 'left' }}>
										<br />
										{
											guidelines.map((guideline) => (
												guideline !== '' ? (
													<>
														<li>{guideline}</li>
														<br />
													</>
												) : ''
											))
										}
									</ul>
								) : ''
							}
						</div>
					</div>
					<hr className={styles.hr} />
					<div className='test-guidelines-actions'>
						<div className='test-guidelines-pp'>
							<Checkbox
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
							<a target="_blank" href={privacyPolicy}><span className='pp-accept'>Accept </span><span className='pp-privacy'>privacy</span> policy</a>
						</div>
						<div className='test-guidelines-start'>
							<Button
								className='text-transform-none'
								size="small"
								variant="contained"
								type='submit'
								disabled={!checked || isDisabled || isLoading}
								onClick={onGoAhead}
							>
								{isLoading ? 'Loading' : resumeStat ? 'Resume' : "Let's begin"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;
