import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import successImg from '../../assets/successImg.png';

import '../../styles/success.scss';

const Success = () => {
	const location = useLocation();

	return (
		<div id="chatbotSuccess">
			<div
				style={{
					height: "100vh",
					width: "100vw",
					color: "#fff",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: "18px",
					fontWeight: "700",
					backgroundColor: "#298d59",
					backgroundImage: "linear-gradient(to right, #23597C, #008095)"
				}}
			>
				<div style={{ width: "40%", marginRight: "15px" }}>
					<img
						src={successImg}
						alt=""
						style={{
							maxWidth: "100%"
						}}
					/>
				</div>
				<div className='chatbot-success-msg' style={{ marginTop: "50px" }}>
					<div style={{ minWidth: "350px", padding: "20px 8px", borderTop: "1px solid #fff", borderBottom: "1px solid #fff" }}>
						<p>Thanks for completing the free trial assessment.</p>
					</div>
					<img src="./hq-logo.png" style={{ maxHeight: "10vh", marginTop: "20px" }} alt='' />
				</div>

			</div>
		</div>
	)
}

export default Success
