import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import '../../styles/driveDetails.scss';

const DriveDetails = (props) => {
	const { driveDetails, bots, fetchBots } = props;

	const location = useLocation();
	const history = useHistory();

	const [selectedWorkFunction, setSelectedWorkFunction] = useState('');
	const [assessmentName, setAssessmentName] = useState('');
	const [assRole, setAssRole] = useState('');

	useEffect(() => {
		if (driveDetails.productId && driveDetails.productId.hasOwnProperty('workfunction') && driveDetails.productId.workfunction.length > 0) {
			setSelectedWorkFunction(driveDetails.productId.workfunction[0].value);
		}

		if(bots.length === 0 && driveDetails.productId && driveDetails.productId.isCustom) {
			fetchBots({ history });
		}
		if(driveDetails.productId?.role)
			setAssRole(driveDetails.productId.role)
	}, [driveDetails]);

	useEffect(() => {
		if(driveDetails.productId && driveDetails.productId.isCustom) {
			bots.forEach((bot) => {
				if (bot.productId === driveDetails.productId.customId) {
					setAssessmentName(bot.name);
				}
			});
		}
	}, [bots]);

	return (
		<div className='driveDetails'>
			<div className="userListTitleContainer" style={{ padding: 0, marginTop: 0 }}>
				<div className="inner-div-details">
					<h2>Drive Details</h2>
				</div>
			</div>
			<div style={{ background: "#fff", borderRadius: 33, boxShadow: '7px 9px 9px rgba(0, 0, 0, 0.25)', padding: 40 }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40 }}>
					<div style={{ fontSize: 18, fontWeight: '400', color: '#8b8b8b', wordBreak: 'break-all' }}>{location.state.workflowName}</div>
					<div style={{ fontSize: 12, fontWeight: '600', color: '#8b8b8b' }}>Date: {new Date(driveDetails.createdDate).toLocaleDateString()}</div>
				</div>
				<div style={{ fontSize: 15, color: '#999', marginBottom: 50, fontWeight: '700' }}>{driveDetails.description}</div>
				{
					assessmentName !== '' ? (
						<div style={{ color: "676767", fontWeight: '700', fontSize: 18, marginBottom: 40 }}>
							Assessment Name: {assessmentName}
						</div>
					) : ''
				}
				{
					assRole !== '' ? (
						<div style={{ color: "676767", fontWeight: '700', fontSize: 18, marginBottom: 40 }}>
							Role: {assRole}
						</div>
					) : ''
				}
				{
					driveDetails.productId && driveDetails.productId.workfunction ? (
						<>
							<div style={{ color: "676767", fontWeight: '700', fontSize: 18, marginBottom: 40 }}>
								Role: {driveDetails.productId.role}
							</div>
							{
								driveDetails.productId && driveDetails.productId.hasOwnProperty('workfunction') && driveDetails.productId.workfunction.length > 0 ? (
									<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: 40 }}>
										<div style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>Critical work function: </div>
										{
											driveDetails.productId && driveDetails.productId.workfunction ? driveDetails.productId.workfunction.map((item) => (
												<div style={{ display: 'flex', marginBottom: 10 }} key={item.value}>
													<div
														style={{
															marginLeft: 20,
															padding: '5px 10px',
															borderRadius: 20,
															fontSize: 14,
															color: (item.value === selectedWorkFunction ? '#fff' : '#000'),
															backgroundColor: (item.value === selectedWorkFunction ? '#479BD2' : '#F5F5F5'),
															cursor: 'pointer'
														}}
														onClick={() => setSelectedWorkFunction(item.value)}
														key={item.value}
													>
														{item.label}
													</div>
													{
														item.weightage ? (
															<div
																style={{
																	marginLeft: 10,
																	padding: '5px 10px',
																	borderRadius: 20,
																	fontSize: 14,
																	color: (item.value === selectedWorkFunction ? '#fff' : '#000'),
																	backgroundColor: (item.value === selectedWorkFunction ? '#479BD2' : '#F5F5F5'),
																	cursor: 'pointer'
																}}
															>
																{item.weightage}%
															</div>
														) : ''
													}
												</div>
											)) : ''
										}
									</div>
								) : ''
							}
							{
								driveDetails.productId && driveDetails.productId.keyTask && selectedWorkFunction ? (
									<div style={{ backgroundColor: '#F8F8F8', padding: '20px 24px', borderRadius: 40 }}>
										{
											driveDetails.productId.keyTask[driveDetails.productId.role][selectedWorkFunction].map((item) => (
												<div className='keyTask' key={item}>{item}</div>
											))
										}
									</div>
								) : ''
							}
						</>
					) : ''
				}
			</div>
		</div>
	)
}

export default DriveDetails;
