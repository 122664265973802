import React, { useState } from 'react';
import { Button } from '@mui/material';

import axios from '../../../axios';
import { validateCreateNewDrive } from '../../../utils/validations';

const AddDefaultDrive = (props) => {
	const { goBack, bot, companyName, change, setChange, onClose, setSnackErr, handleSnackClick } = props;
	const [currTab, setCurrTab] = useState(0)
	const [name, setName] = useState('');
	const [role, setRole] = useState('');
	const [desc, setDesc] = useState('');
	const [videoQuestionsData, setVideoQuestionsData] = useState([{ 'question': 'QQQ', time: 1 }])
	const onChangeName = (e) => {
		setName(e.target.value);
	}
	const addNewQuestion = (e, i) => {
		const addBotQuestion = {
			question: '',
			time: 1
		};
		const newAddBotQuestionData = [...videoQuestionsData];
		newAddBotQuestionData.splice(i+1, 0, addBotQuestion);
		setVideoQuestionsData([...newAddBotQuestionData]);
	}

	const removeQuestion = (e, i) => {

		const newAddBotQuestionData = [...videoQuestionsData];
		newAddBotQuestionData.splice(i, 1);
		setVideoQuestionsData([...newAddBotQuestionData]);
	}
	const onChangeRole = (e) => {
		setRole(e.target.value);
	}

	const onChangeDesc = (e) => {
		setDesc(e.target.value);
	}
	const onChangeQuestionText = (e, index) => {
		const newAddBotQuestionData = [...videoQuestionsData];
		newAddBotQuestionData[index].question = e.target.value;
		setVideoQuestionsData([...newAddBotQuestionData]);
	}

	const onChangeQuestionTime = (e, index) => {
		const newAddBotQuestionData = [...videoQuestionsData];
		newAddBotQuestionData[index].time = e.target.value;
		setVideoQuestionsData([...newAddBotQuestionData]);
	}

	const sendWorkflowDefault = () => {
		const errorResult = validateCreateNewDrive(name, desc);
		let allTIME = 0;
		for (let i in videoQuestionsData) {
			allTIME += parseInt(videoQuestionsData[i].time)
		}
		let assessment = {
			total_time: allTIME,
			timer: allTIME + 2 * videoQuestionsData.length,
			questions: videoQuestionsData
		}
		if (errorResult.isValid) {
			const uri = "/company/workflow/";
			const getData = {
				workflowName: name,
				workflowDescription: desc,
				productId: {
					isMegacase: false,
					isCustom: true,
					customId: 'Video',
					role : role
				},
				problem: 'na',
				title: 'na',
				companyName: companyName,
				assessmentTime: assessment.timer,
				assessment: assessment
			}

			axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then(() => {
					setChange(change + 1);
				})
				.catch((err) => {
					console.log(err);
				});

			onClose();
		} else {
			Object.keys(errorResult.errors).forEach((item) => {
				setSnackErr(errorResult.errors[item]);
			});
			handleSnackClick();
		}
	}

	return (
		<div>
			{currTab === 0 ? (
				<div className='default-form'>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
						{/* <div className='back-btn' onClick={goBack}>
					<div></div>
				</div> */}
						<div className='title'>Create new drive</div>
					</div>
					<div>
						<input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' id="testNewDriveFlow-selector-3" />
					</div>
					<div>
						<textarea onChange={onChangeDesc} value={desc} placeholder='Add description' id="testNewDriveFlow-selector-4" />
					</div>
					<div>
						<input type="text" onChange={onChangeRole} value={role} placeholder='Add role' id="testNewDriveFlow-selector-5" />
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button size="small" variant="contained" className='action' onClick={() => setCurrTab(2)} style={{ padding: '10px 40px', borderRadius: 40 }} id="testNewDriveFlow-selector-5">Next</Button>
					</div>
				</div>) : ""
			}
			{currTab === 2 ? (
				<div>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
						<div className='back-btn' onClick={()=>setCurrTab(0)}>
							<div></div>
						</div>
						<div className='title'>Customise</div>
					</div>
					{videoQuestionsData.map((vidq, i) => (
						<div style={{ marginTop: 15 }} key={i}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ fontSize: 16, fontWeight: '700', color: '#878787', marginRight: 50 }}>Video Question {i + 1}</div>
								<div style={{ position: 'relative' }}>
									<input style={{ lineHeight: '21px', width: 100, color: '#858585', outline: 'none', border: 'none', padding: '5px 10px', borderRadius: 8, backgroundColor: '#F5F5F5' }} type='number' placeholder='Choose Time' value={vidq.time} onChange={(e) => onChangeQuestionTime(e, i)} />
									{
										vidq.time !== '' ? (
											<div style={{ position: 'absolute', top: 6, right: 50, color: '#858585', fontWeight: 600, fontSize: 14 }}>min</div>
										) : ''
									}
								</div>
								<div style={{ marginLeft: 20, fontSize: 12, fontWeight: '600', color: '#9D9D9D' }}>Time Limit</div>
							</div>
							<div style={{ display: 'flex', marginTop: 20 }}>
								<div style={{ flex: 1 }}>
									<textarea style={{ lineHeight: '21px', width: '100%', color: '#858585', outline: 'none', border: 'none', padding: '10px 20px', marginTop: 0, boxSizing: 'border-box', fontSize: 14 }} type='text' placeholder='Add your question here...' value={vidq.question} onChange={(e) => onChangeQuestionText(e, i)} rows={4} />
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
									<div className='add-question-btn' onClick={(e) => addNewQuestion(e, i)}></div>
									<div className='remove-question-btn' onClick={(e) => removeQuestion(e, i)}></div>
								</div>
							</div>
						</div>

					))}

					<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50 }}>
						<Button size="small" variant="contained" className='action' onClick={sendWorkflowDefault} style={{ padding: '10px 40px', borderRadius: 40 }} id="testNewDriveFlow-selector-5">Create</Button>
					</div>
				</div>) : ""
			}
		</div>
	)
}

export default AddDefaultDrive;
