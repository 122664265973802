import React,{useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../../utils/featureAccess";
import { getDashboardLogo, getFunnelData, getSettings, getUserName, getUserRole,getUserPlan,getSaasPlanDetails,getCompanyFeatures } from '../../selectors/app';

import DashboardActive from '../../assets/Icons/dashboard-active.png';
import Logo from '../../assets/Logo.png';
import PreScreening from '../../assets/pre-screening.svg';
import PreScreeningActive from '../../assets/pre-screening-active.svg';
import TasksIcon from '../../assets/Icons/tasks.png';
import ContactsIcon from '../../assets/Icons/contacts.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';

import "./sidebar.css";

function Sidebar(props) {
	const { hidden = false, userName, settings, userPlan,saasPlanDetails,companyFeatures,dashboardLogo } = props;
	const [allowPreScreening,setAllowPrescreening] = useState(checkCompanyFeatureAccess(companyFeatures, 'preScreening', null) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'preScreening', null))
	
	const location = useLocation();

	return (
		<div style={{ width: "20vw", color: "black" }}>
			<div className="sidebar" style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="sidebarWrapper" style={{ flex: 1 }}>
					<div className="logo-div">
						<img style={{ height: "auto", width: "11vw" }} src={dashboardLogo} alt="" />
					</div>
					<div className="user-div">
						<div className="user-details">
							<h4 style={{ padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
						</div>
					</div>
					<div className="sidebarMenu">
						<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
							<Button><img src={DashboardActive} alt="" />&nbsp;Recruitment Drives</Button>
						</Link>
					</div>
					{allowPreScreening ? (
					<div className={window.location.pathname === "/pre-screening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link to={{ pathname: "/pre-screening/dashboard", state: { sidebar: "preScreening" } }} className="link">
							<Button><img src={window.location.pathname === "/pre-screening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />&nbsp;Pre-screening Stage</Button>
						</Link>
					</div>):""
				}

					<div className={location.state.sidebar === "quickLook" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ display: hidden ? "none" : "" }}>
						<Link to={{ pathname: "/workflows/" + location.state.workflowName, state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} className="link">
							<Button className='dashboard-fifth-step'><img src={TasksIcon} alt="" />&nbsp;Quick Overview</Button>
						</Link>
						<ul className="sidebarList">
							<Link to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "applicants" } }} className="link" id="applicantsFlow-selector-1">
								<li className={location.state.sidebar === "applicants" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									All Applicants
								</li>
							</Link>
							<Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "finalised" } }} className="link dashboard-sixth-step" id="reviewedFlow-selector-1">
								<li className={location.state.sidebar === "finalised" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Reviewed
								</li>
							</Link>
							<Link to={{ pathname: "/analytics", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "analytics" } }} className="link dashboard-seventh-step" id="analytics-flow-selector-4">
								<li className={location.state.sidebar === "analytics" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Analytics
								</li>
							</Link>
							<Link to={{ pathname: "/settings", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "settings" } }} className="link dashboard-eighth-step" id="settingsFlow-selector-1">
								<li className={location.state.sidebar === "settings" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Settings & Drive Details
								</li>
							</Link>
						</ul>
					</div>
					<div className={window.location.pathname === "/new-applicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/new-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "invitations" } }} id="manageInvitation-selector-1">
							<Button className="dashboard-second-step"><img src={DashboardActive} alt="" />&nbsp;Manage Invitations</Button>
						</Link>
					</div>
					<div className={location.state.sidebar === "reviewApplicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "reviewApplicants" } }} id="reviewApplicantsFlow-selector-1">
							<Button className='dashboard-third-step'><img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;Review Applicants</Button>
						</Link>
					</div>
					<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none" }}>
							<Button><img src={ContactsIcon} alt="" />&nbsp;Manage Team</Button>
						</Link>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 10px', borderTop: '1px solid #EBEFF2' }}>
					<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
					<div style={{ display: 'flex' }}>
						<img src={Logo} alt='' style={{ height: 14 }} />
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	settings: getSettings(),
	dashboardLogo: getDashboardLogo(),
	saasPlanDetails: getSaasPlanDetails(),
	companyFeatures: getCompanyFeatures(),
	userPlan: getUserPlan()
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
